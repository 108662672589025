import { template as template_f676965a85cb4fd09a525a4fc59664b5 } from "@ember/template-compiler";
const FKText = template_f676965a85cb4fd09a525a4fc59664b5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
