import { template as template_bb88b490aca34e569e96609aa354b74b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_bb88b490aca34e569e96609aa354b74b(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
